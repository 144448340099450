(() => {
  if (window.customElements.get('pr-the-header')) return;

  class PrideraiserTheHeader extends HTMLElement {
    connectedCallback() {
      this.role = 'banner';

      this.addEventListener('click', (event) => {
        const navToggle = (event.target as HTMLElement).closest(
          '[aria-controls="site-nav"]'
        );
        if (navToggle) {
          this.querySelectorAll('[aria-controls="site-nav"]').forEach(
            (toggle) => {
              toggle.setAttribute(
                'aria-expanded',
                toggle.getAttribute('aria-expanded') === 'false'
                  ? 'true'
                  : 'false'
              );
            }
          );
        }
      });
    }
  }

  window.customElements.define('pr-the-header', PrideraiserTheHeader);
})();
